import React ,{useEffect,useState}from "react";
import Banner from "../components/banner";

import NewsLetter from "../components/newsletter";
import MenuFooter from "../components/menu_footer";
import Politicas from "../components/politicas";
import Redes from "../components/redes";
import Copyright from "../components/copyright";

import $ from 'jquery';

import BlogsCards from "../components/blogs_cards";

import BannerMobile from "../components/banner_mobile";

import CardContacto from "../components/card_contacto";
import CardContactoMobile from "../components/card_contacto_mobile";
import * as app from "../fetch/fetchApi";


const PlantillaBlog = () => {
  const isBrowser = typeof window !== "undefined"
  let width;
  /**
   let datos = this.state.data;
          let datosInstagram = this.state.dataInstagram;
          let datosEventos = this.state.dataEvento
          let datosMenu = this.state.dataMenu
          let datosNodo = this.state.dataNodo
          let datosComplementa = this.state.dataComplementa
          let datosMenuFooter = this.state.dataMenuFooter
          let datosPrecios = this.state.dataPrecio
          let datosBlog=this.state.dataBlog
          let datosTarifas = this.state.dataTarifas
   */

  
  const [datosMenu, setDataMenu] = useState({})
  
  useEffect(() => {
    // get data from GitHub api
   
    fetch(app.url+`/node-api/m22_item_menu/main-menu`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        console.log(resultData)
        setDataMenu(resultData)
      })
       // set data for the number of stars
  }, [])
  const [datosNodo, setDataNodo] = useState([])
  const [datosPrecios, setDataPrecios] = useState([])
  const [datosTarifas, setDataTarifa] = useState([])

  useEffect(() => {
    
    // get data from GitHub api
    if (isBrowser) {
    console.log(window.location.pathname)
let id = window.location.pathname
id = id.substring(1);
var data={'path':id}
    console.log(JSON.stringify( data,null,4))
fetch(app.url+"/node-api/m22_get_content_node/",{
  method:'POST',
  headers:{'Content-type':'application/json'},
  body:JSON.stringify( data,null,4)
})
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        console.log(resultData)
        setDataNodo(resultData)
        
      })
       // set data for the number of stars
    }
  }, [])
  const [datosComplementa, setDataComplementa] = useState([])
  useEffect(() => {
    // get data from GitHub api
    fetch(app.url+`/node-api/views/prueba_get_interior_blog?display_id=services_1&format_output=0`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        console.log(resultData)
        setDataComplementa(resultData)
      })
       // set data for the number of stars
  }, [])
  const [datosMenuFooter, setDataMenuFooter] = useState({})
  useEffect(() => {
    // get data from GitHub api
    fetch(app.url+`/node-api/m22_item_menu/menu-foooter-menu-2019`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        console.log(resultData)
        setDataMenuFooter(resultData)
      })
       // set data for the number of stars
  }, [])
 

  const [datosBlogCard, setDataBlogCard] = useState([])
  useEffect(() => {
    // get data from GitHub api
    fetch(app.url+`/node-api/views/prueba_get_card_blog?display_id=services_1&format_output=0`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        console.log(resultData)
        setDataBlogCard(resultData)
      })
       // set data for the number of stars
  }, [])
  
  const [datosBlog, setDataBlog] = useState([])
  useEffect(() => {
    // get data from GitHub api
    fetch(app.url+`/node-api/views/prueba_get_interior_blog?display_id=services_1&format_output=0&args[0]=2157`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        console.log(resultData)
        setDataBlog(resultData)
      })
       // set data for the number of stars
  }, [])

  const [datosBefore, setDataBefore] = useState([])
  useEffect(() => {
    // get data from GitHub api
    if (isBrowser) {
      console.log(window.location.pathname)
  let id = window.location.pathname
  id = id.substring(1);
  var data={'path':id}
      console.log(JSON.stringify( data,null,4))
    fetch(app.url+`/node-api/m22_get_before_blog/`,{
      method:'POST',
      headers:{'Content-type':'application/json'},
      body:JSON.stringify( data,null,4)
    })
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        console.log(resultData)
        setDataBefore(resultData)
      })
    }
       // set data for the number of stars
  }, [])

  const [datosNext, setDataNext] = useState([])
  useEffect(() => {
    // get data from GitHub api
    if (isBrowser) {
      console.log(window.location.pathname)
  let id = window.location.pathname
  id = id.substring(1);
  var data={'path':id}
      console.log(JSON.stringify( data,null,4))
    fetch(app.url+`/node-api/m22_get_next_blog/`,{
      method:'POST',
      headers:{'Content-type':'application/json'},
      body:JSON.stringify( data,null,4)
    })
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        console.log(resultData)
        setDataNext(resultData)
      })
       // set data for the number of stars
    }
  }, [])

  if (isBrowser) {
 width= window.innerWidth 
console.log(width) 

$(document).ready(function () {
  const nodes = document.getElementsByTagName("img");
for (let index = 0; index < nodes.length; index++) {
console.log(nodes[index].getAttribute("src")) 
if (!nodes[index].getAttribute("src").includes(app.url)) {
  //nodes[index].style.whiteSpace="unset"
  var urlImage = app.url+nodes[index].getAttribute("src")
  nodes[index].setAttribute("src",urlImage)
} 
}
})
  }
  var contenido = datosBlog;
  var url= datosNodo.field_multimedia!==undefined?datosNodo.field_multimedia.und[0].uri:'';
  url=url.replace('public://', '')
  console.log(  url)

  
return (
    
    <div>
              { width <1100?<Banner data={datosMenu}/>:<BannerMobile data={datosMenu} />
}
<div className="row justify-content-between removeMax  px-md-5" style={{marginTop:'100px'}}>
    <div className="col-xl-8 col-12">
    {console.log( datosBefore)}
{console.log( datosNext)}
{datosNodo.field_multimedia!==undefined?<img src={app.url+"/sites/default/files/"+url} />:''}
{datosNodo!==undefined?<div className="tituloInteres">{datosNodo.title}</div>:''}
{  datosNodo.body!==undefined?<div style={{overflowX:'hidden'}} dangerouslySetInnerHTML={{__html: datosNodo.body.und[0].value}} />:'' }
<div>
<p>¿Te gustó este artículo? Compártelo por el medio de tu preferenecia</p>
<div >
<ul className={'d-flex row justify-content-md-start justify-content-between ps-2'} style={{listStyle:'none'}}>
            <li className={'col-1 px-0 mx-0'}>
                <a href="https://api.whatsapp.com/send?phone=527341142287" target="_blank">
                    <div class="whatsapp2">
                        <i></i>
                    </div>
                </a>
            </li>
            
            <li  className={'col-1  px-0 mx-0'}>
                <a href="mailto:informes@lasestacas.com" target="_blank">
                    <div class="email2">
                        <i></i>
                    </div>
                </a>
            </li>
            <li  className={'col-1  px-0 mx-0'}>
                <a href="https://www.facebook.com/lasestacas.parquenatural" target="_blank">
                    <div class="facebook2">
                        <i></i>
                    </div>
                </a>
            </li>
            <li  className={'col-1  px-0 mx-0 '}>
                <a href="https://www.addtoany.com/add_to/facebook_messenger" target="_blank">
                    <div class="messenger2">
                        <i></i>
                    </div>
                </a>
            </li>
           
        </ul>
</div>
</div>
<div className="row mt-5 justify-content-between" style={{maxWidth:'unset'}}> 
{datosBefore!==undefined&&datosBefore[0]!=='node/'?<a href={'/'+datosBefore[0]} className="col-6 links" >Articulo anterior</a>:''}
{datosNext!==undefined&&datosNext[0]!=='node/'?<a href={'/'+datosNext[0]} className="col-6 links">Articulo siguiente</a>:''}
</div>
        </div>
        <div className="col-xl-3 col-12">
        <div className="d-block d-md-none d-xl-block mt-5">
          <CardContacto />
          </div>
          <div className="mt-0 d-none d-md-block d-xl-none">
          <CardContactoMobile />
          </div>
                  </div>

</div>
{datosBlogCard.length!==0?<BlogsCards data={datosBlogCard} />:''}

            
        <footer className={'l-footer panel'} role={'contentinfo'}>
            <div className={'footer'}>
            <NewsLetter />
            <MenuFooter data={datosMenuFooter}/>

            <Politicas />
            <Redes />
            </div>
            <Copyright />
        </footer>
        </div>
  );
};

export default PlantillaBlog;